import { type VariantProps, cva } from "class-variance-authority";
import React from "react";
import { cn } from "utils/utils";

export const paragraphVariants = cva("inline", {
  variants: {
    variant: {
      primary: "text-primary",
      secondary: "text-secondary",
      secondaryDarker: "text-secondaryDarker",
      white: "text-white",
    },
    size: {
      lg: "text-lg leading-[130%]",
      md: "text-base leading-[130%]",
      sm: "text-sm leading-[140%]",
      xs: "text-xs leading-5",
    },
    fontWeight: {
      regular: "font-normal",
      bold: "font-bold",
      medium: "font-semibold",
    },
  },
});

export interface ParagraphProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof paragraphVariants> {}

const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  (
    {
      className,
      variant,
      size = "lg",
      fontWeight = "regular",
      color,
      ...props
    },
    ref,
  ) => {
    return (
      <p
        {...props}
        ref={ref}
        className={cn(
          paragraphVariants({ variant, size, fontWeight }),
          className,
        )}
      />
    );
  },
);
Paragraph.displayName = "Paragraph";
export { Paragraph };
